import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';

import { Role } from '../../models';
import { RolesApiService } from '../../services';
import { RoleDetails } from '@feature/roles/transformers';
import { plainToInstance } from 'class-transformer';

@Injectable({ providedIn: 'root' })
export class RolesService {
  get roleList$(): Observable<Role[]> {
    return this.#roleList$.asObservable();
  }

  get role$(): Observable<RoleDetails> {
    return this.#role$.asObservable();
  }

  readonly #roleList$ = new BehaviorSubject<Role[]>([]);
  readonly #role$ = new BehaviorSubject<RoleDetails>({} as RoleDetails);

  constructor(private rolesApiService: RolesApiService) {}

  getAll(): Observable<Role[]> {
    return this.rolesApiService.list().pipe(tap((roles) => this.#roleList$.next(roles)));
  }

  getById(roleId: string): Observable<Role> {
    return this.rolesApiService.get(roleId)
      .pipe(tap((role) => this.#role$.next(plainToInstance(RoleDetails, role))));
  }

  create(payload: Partial<Role>): Observable<Role[]> {
    return this.rolesApiService.create(payload).pipe(switchMap(() => this.getAll()));
  }

  update(roleId: string, payload: Partial<Role>): Observable<Role[]> {
    return this.rolesApiService.update(roleId, payload).pipe(switchMap(() => this.getAll()));
  }

  delete(roleId: string): Observable<Role[]> {
    return this.rolesApiService.delete(roleId).pipe(switchMap(() => this.getAll()));
  }
}
